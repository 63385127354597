import React from 'react';
import TypedText from './TypedText';

export default function Intro({ title, text, typedText }) {
  return (
    <article css={styles.root}>
      <header css={styles.content}>
        <TypedText strings={typedText} />
      </header>
    </article>
  );
}

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center'
  },

  header: {
    marginTop: 0,
    marginBottom: '0.8rem',
    fontSize: '1.3rem'
  },

  content: {
    order: 2,
    marginLeft: '1rem',

    '& p': {
      margin: 0,
      fontSize: '0.9rem'
    }
  }
};
