import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Intro from '../components/Intro';

function getHomeData(data) {
  const edge = data.allYamlYaml.edges.find(e => e.node.home);
  return edge.node.home;
}

//      <TypedText strings={Array.from(profile.typed_strings)} />

export default function IndexPage({ data }) {
  const { profile } = getHomeData(data);
  return (
    <Layout>
      <SEO title="Chris Ingoe" />
      <h1>Chris Ingoe</h1>
      <div css={styles.profile}>
        <Intro
          title={profile.title}
          text={profile.text}
          typedText={profile.typed_strings.split('|')}
        />
      </div>
    </Layout>
  );
}

const styles = {
  profile: {
    marginTop: '3rem'
  }
};

export const pageQuery = graphql`
  query {
    allYamlYaml {
      edges {
        node {
          home {
            profile {
              title
              text
              typed_strings
            }
          }
        }
      }
    }
  }
`;
