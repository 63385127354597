import React from 'react';
import Typed from 'typed.js';

class TypedText extends React.Component {
  componentDidMount() {
    const { strings } = this.props;

    const options = {
      strings: strings,
      typeSpeed: 5,
      backSpeed: 0,
      backDelay: 3000,
      startDelay: 3000,
      smartBackspace: false,
      loop: true,
      loopCount: 2,
      shuffle: false,
      fadeOut: true,
      fadeOutDelay: 500,
      showCursor: false,
      cursorChar: '|'
    };

    this.typed = new Typed(this.el, options);
  }

  componentWillUnmount() {
    this.typed.destroy();
  }

  render() {
    return (
      <div css={styles.wrap}>
        <div css={styles.typeWrap}>
          <span
            style={{ whiteSpace: 'pre' }}
            ref={el => {
              this.el = el;
            }}
          />
        </div>
      </div>
    );
  }
}

export default TypedText;

const styles = {
  content: {
    order: 2,
    marginLeft: '1rem',
    '& p': {
      margin: 0,
      fontSize: '0.9rem'
    }
  }
};
